import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
  redirect,
} from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { useUserStore } from "./Store";

import Home from "./pages/Home";
import Login from "./pages/Login";
import Profile from "./pages/Profile";
import Setup from "./pages/Setup";
import { initAuth } from "./pages/Auth";
import Match from "./pages/Match";

const loginRedirects = (user, url) => {
  const path = new URL(url).pathname;
  if (user === null) {
    return path === "/login" ? null : redirect("/login");
  }
  if (user) {
    if (!user.displayName || !user.photoURL) {
      return path.startsWith("/setup") ? null : redirect("/setup");
    } else if (path.startsWith("/setup") || path.startsWith("/login")) {
      return redirect("/");
    }
  }
  return null;
};

export default function App() {
  const { me: user, setUser } = useUserStore();
  if (user === undefined) {
    initAuth(setUser);
    return <></>;
  }

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        path="/"
        loader={({ request }) => loginRedirects(user, request.url)}
      >
        <Route
          index
          element={<Home />}
          loader={({ request }) => loginRedirects(user, request.url)}
        />
        <Route
          path="/login"
          element={<Login />}
          loader={({ request }) => loginRedirects(user, request.url)}
        />
        <Route
          path="/setup"
          element={<Setup />}
          loader={({ request }) => loginRedirects(user, request.url)}
        />
        <Route
          path="/profile"
          element={<Profile />}
          loader={({ request }) => loginRedirects(user, request.url)}
        />
        <Route
          path="/match"
          element={<Match />}
          loader={({ request }) => loginRedirects(user, request.url)}
        />
      </Route>
    )
  );

  return <RouterProvider router={router} />;
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
