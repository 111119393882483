import React from "react";
import clsx from "clsx";
import { Loader2Icon } from "lucide-react";

interface ButtonProps {
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  text: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

export default function Button(props: ButtonProps) {
  return (
    <button
      onClick={props.onClick}
      disabled={props.disabled || props.loading}
      className={clsx(
        "h-10 rounded-3xl px-4",
        props.disabled || props.loading
          ? "bg-gray-200 text-zinc-300"
          : "bg-k-red text-white",
        "flex justify-center items-center text-white text-base font-medium",
        props.className
      )}
    >
      {props.loading ? (
        <Loader2Icon className="animate-spin" size={20} />
      ) : (
        props.text
      )}
    </button>
  );
}
