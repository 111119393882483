import { initializeApp } from "firebase/app";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import {
  getAuth,
  signInWithPhoneNumber,
  onAuthStateChanged,
  updateProfile,
} from "firebase/auth";

export function initAuth(setUser: any) {
  console.log("init auth");

  const firebaseConfig = {
    apiKey: "AIzaSyAdiP3PUDw9WitUl0P2UvvHvdRyALuhEbA",
    authDomain: "katchup-368314.firebaseapp.com",
    projectId: "katchup-368314",
    storageBucket: "katchup-368314.appspot.com",
    messagingSenderId: "724238288123",
    appId: "1:724238288123:web:f0e54da6587af6b96b5c5e",
    measurementId: "G-28G8ZCG1CG",
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  console.log("init app.");
  let auth = getAuth(app);
  auth.languageCode = "en-us";

  // Set up listener for currently logged in user
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      setUser(user);
      console.log(user);
    } else {
      setUser(null);
    }
  });
}

export function sendSMS(phoneNumber: string) {
  let auth = getAuth();
  console.log(phoneNumber);
  signInWithPhoneNumber(auth, phoneNumber, (window as any).recaptchaVerifier)
    .then((confirmationResult) => {
      // SMS sent. Prompt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code).
      (window as any).confirmationResult = confirmationResult;
      console.log("sent SMS to", phoneNumber);
    })
    .catch((error) => {
      alert(error);
    });
}

export function sendCode(code: string) {
  (window as any).confirmationResult
    .confirm(code)
    .then((result: any) => {
      // User signed in successfully.
      const user = result.user;
      console.log(user);
      // ...
    })
    .catch((error: any) => {
      // User couldn't sign in (bad verification code?)
      // ...
      alert(error);
    });
}

export function updateDisplayName(displayName: string, setUser: any) {
  const auth = getAuth();
  if (!auth.currentUser) {
    return;
  }
  updateProfile(auth.currentUser, {
    displayName: displayName,
  })
    .then(() => {
      // Profile updated!
      // ...
      setUser(getAuth().currentUser);
    })
    .catch((error) => {
      // An error occurred
      // ...
      alert(error);
    });
}

export function updatephotoURL(photoFile: File | null, setUser: any) {
  const auth = getAuth();
  if (!auth.currentUser) {
    return;
  }

  if (!photoFile) {
    updateProfile(auth.currentUser, {
      photoURL: "",
    })
      .then(() => {
        setUser(getAuth().currentUser);
      })
      .catch((error) => {
        // An error occurred
        // ...
        alert(error);
      });
    return;
  }

  const extension = photoFile.type.split("/").pop() ?? "";
  if (!["svg", "png", "jpg", "jpeg"].includes(extension)) {
    alert("Please select a .svg, .png or .jpg file.");
    return;
  }
  const storage = getStorage();
  const storageRef = ref(
    storage,
    `/profilePictures/${auth.currentUser.uid}.${extension}`
  );
  const uploadTask = uploadBytesResumable(storageRef, photoFile);

  uploadTask.on(
    "state_changed",
    (snapshot) => {},
    (err) => alert("upload failed"),
    () => {
      // URL
      getDownloadURL(uploadTask.snapshot.ref).then((url) => {
        const auth = getAuth();
        if (!auth.currentUser) {
          return;
        }
        updateProfile(auth.currentUser, {
          photoURL: url,
        })
          .then(() => {
            setUser(getAuth().currentUser);
          })
          .catch((error) => {
            // An error occurred
            // ...
            alert(error);
          });
      });
    }
  );
}
