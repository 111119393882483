import React from "react";
import CalendarColumn from "../components/CalendarColumn";
import Navbar from "./Navbar";
import CalendarFooter from "../components/CalendarFooter";
import CreateOptions from "../components/CreateOptions";

function dayWithOffset(offset: number) {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate() + offset);
}

function Home() {
  return (
    <div className="flex flex-col mx-auto max-w-screen-sm overflow-auto h-screen">
      <Navbar />
      {/* max-h-[32rem] here to install max height on calendar  */}
      <div className="flex justify-between space-x-2 px-2 grow ">
        <CalendarColumn day={dayWithOffset(0)} />
        <CalendarColumn day={dayWithOffset(1)} />
        <CalendarColumn day={dayWithOffset(2)} />
        <CalendarColumn day={dayWithOffset(3)} />
        <CalendarColumn day={dayWithOffset(4)} />
        <CalendarColumn day={dayWithOffset(5)} />
        <CalendarColumn day={dayWithOffset(6)} />
      </div>
      <div className="px-2 my-2">
        <CreateOptions />
      </div>
      <CalendarFooter />
    </div>
  );
}

export default Home;
