import { MapPinIcon, UsersIcon } from "lucide-react";
import React, { useState } from "react";
import { useCalendarStore } from "../Store";

interface CreateOptionsState {
  // TODO: list of group IDs
  // for now, just a list of group names
  selection: string[];
  // TODO: location ID stored in backend
  location: string;
}

export default function CreateOptions() {
  const active = useCalendarStore((state) => state.selectedPeriods.length > 0);
  const [state, setState] = useState<CreateOptionsState>({
    selection: ["All friends"],
    location: "New York",
  });

  const buttons = (
    <div className="grid grid-cols-2 space-x-2 text-k-red font-lg h-full">
      <div className="flex items-center bg-k-lightred grow rounded-md">
        <UsersIcon size={20} className="ml-4 mr-2" />
        <span>
          {state.selection.length !== 1
            ? `${state.selection.length} groups`
            : state.selection[0]}
        </span>
      </div>
      <div className="flex items-center bg-k-lightred grow rounded-md">
        <MapPinIcon size={20} className="ml-4 mr-2" />
        <span>{state.location ? state.location : "Choose location"}</span>
      </div>
    </div>
  );

  const enterAvailabilitiesSuggestion = (
    <div className="text-gray-400 text-center px-16">
      To start, please choose a time slot above.
    </div>
  );

  return (
    <div className="h-[54px]">
      {active ? buttons : enterAvailabilitiesSuggestion}
    </div>
  );
}
