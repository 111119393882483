import clsx from "clsx";
import React, { useState } from "react";
import useSWR from "swr";
import { Loader2, Loader2Icon } from "lucide-react";
import { useUserStore, useCalendarStore } from "../Store";
import { ReactComponent as MorningIcon } from "../assets/Morning.svg";
import { ReactComponent as NoonIcon } from "../assets/Noon.svg";
import { ReactComponent as EveningIcon } from "../assets/Evening.svg";
import { useNavigate } from "react-router-dom";
import { BlockState } from "../utils/mytypes";

export function createKey(uid: string, start: number, end: number) {
  return "https://gongy--katchup-api-get-block-state.modal.run?" +
    new URLSearchParams({
      uid: uid,
      start: start.toFixed(0),
      end: end.toFixed(0),
    }).toString();
}

export default function Block({
  type,
  start,
  end,
}: {
  type: string;
  start: number;
  end: number;
}) {
  const { me } = useUserStore();
  const navigate = useNavigate();
      
  const key = createKey(me?.uid ?? "", start, end);
  const { selected, modifying, addPeriod, removePeriod } = useCalendarStore(
    (state) => ({
      selected: state.contains(key, start, end),
      ...state,
    })
  );

  // Populate state SWR from API
  const { data, isLoading: swrLoading } = useSWR<BlockState>(key, (url) =>
    fetch(url).then((r) => r.json())
  );

  const loading = swrLoading || !data || (modifying && selected);
  const clickable = !swrLoading && !modifying && data;

  let display = <Loader2 size={12} className="animate-spin mb-16" />;
  if (!loading) {
    const opacities = [
      "opacity-100",
      "opacity-50",
      "opacity-25",
      "opacity-10",
      "opacity-5",
    ];

    const usersToShow = [
      ...data.groups.flatMap((group) => group.members.map((member) => member.user)),
      ...data.matches,
    ].filter(user => user.uid !== me?.uid);
    const countToShow = data.active && !data.confirmed ? usersToShow.length.toString() : "";

    const bubbles = usersToShow
      .slice(0, 4)
      .map((user, index: number, array: any[]) => (
        <div key={user.uid} className="h-8 w-8 rounded-full -mt-[1.7rem]">
          <div className="absolute h-8 w-8 overflow-hidden rounded-full bg-k-base">
            <img
              className={clsx(
                "object-cover w-8 h-8 rounded-full",
                !data.active && "blur-md",
                opacities[array.length - index - 1]
              )}
              src={user.photoURL}
            />
          </div>
        </div>
      ));

    if (usersToShow.length === 0 && data.active)
      bubbles.push(
        <div
          key="fake"
          className="h-8 w-8 rounded-full bg-k-base -mt-[1.7rem]"
        />
      );

    display = (
      <>
        <div className="pt-2 h-6 flex flex-col-reverse justify-end text-xs tabular-nums text-lg uppercase mt-[1.7rem]">
          {bubbles}
        </div>
        <div className="h-6 mb-2">{countToShow}</div>
      </>
    );
  }

  const handleSelect = (event: React.MouseEvent) => {
    event.preventDefault();
    if (clickable) {
      if (!data.active) {
        // Add to creation flow
        if (selected) removePeriod(key, start, end);
        else addPeriod(key, start, end);
      } else {
        // Show pop-up for match state!
        const matchURL =
          `/match?` +
          new URLSearchParams({
            type: type,
            start: start.toFixed(0),
            end: end.toFixed(0),
          }).toString();
        navigate(matchURL);
      }
    }
  };

  // darkRed if confirmed, and your group contains >= 2 confirmed
  const darkRed =
    !loading &&
    data?.confirmed &&
    data.groups[0].members.filter((member) => member.status == "confirmed").length >= 2;
  // Light red if you are active or confirmed and not dark red
  const lightRed = !loading && !darkRed && data?.active;
  // White otherwise
  const white = !darkRed && !lightRed;

  let icon = <></>;
  const iconClass = clsx(
    "w-6 h-6 mt-2 text-left",
    white && "fill-zinc-200 stroke-zinc-200",
    lightRed && "fill-k-red stroke-k-red",
    darkRed && "fill-white stroke-white"
  );
  if (type == "morning") icon = <MorningIcon className={iconClass} />;
  if (type == "noon") icon = <NoonIcon className={iconClass} />;
  if (type == "evening") icon = <EveningIcon className={iconClass} />;

  return (
    <button
      className={clsx(
        "flex flex-col justify-between items-center",
        "mt-2 w-full h-0 min-h-[7rem] rounded-md grow",
        !selected && clickable && "hover:ring-2 hover:ring-k-lightred",
        selected && "ring-2 ring-k-red",
        darkRed && "bg-k-red",
        lightRed && "bg-k-lightred",
        white && "bg-white"
      )}
      disabled={!clickable}
      onClick={handleSelect}
    >
      {icon}
      {display}
    </button>
  );
}
