import { User } from "firebase/auth";
import { create } from "zustand";

interface UserState {
  me: User | null | undefined; // null = logged out, undefined = not updated from firebase
  setUser: (user: User | null) => void;
}

export const useUserStore = create<UserState>()((set) => ({
  me: undefined,
  setUser: (me: User | null) => set({ me }),
}));

interface CalendarState {
  selectedPeriods: [string, number, number][]; // key, start, end
  addPeriod: (key: string, start: number, end: number) => void;
  removePeriod: (key: string, start: number, end: number) => void;
  contains: (key: string, start: number, end: number) => boolean;
  clearPeriods: () => void;
  modifying: boolean; // lock for display to show
  setModifying: (b: boolean) => void;
}

export const useCalendarStore = create<CalendarState>()((set, get) => ({
  selectedPeriods: [],
  addPeriod: (key: string, start: number, end: number) =>
    set((state) => ({
      selectedPeriods: [...state.selectedPeriods, [key, start, end]],
    })),
  removePeriod: (key: string, start: number, end: number) =>
    set((state) => {
      console.log("remove", start);
      const index = state.selectedPeriods.findIndex(
        (p) => p[0] === key && p[1] === start && p[2] === end
      );
      if (index >= 0) state.selectedPeriods.splice(index, 1);
      return { selectedPeriods: state.selectedPeriods };
    }),
  contains: (key: string, start: number, end: number) =>
    get().selectedPeriods.find(
      (p) => p[0] === key && p[1] === start && p[2] === end
    ) !== undefined,
  clearPeriods: () => set({ selectedPeriods: [] }),

  modifying: false,
  setModifying: (b: boolean) => set({ modifying: b }),
}));
