import React from "react";
import { ReactComponent as LogoIcon } from "../assets/Logo.svg";
import { ReactComponent as LogoWhiteIcon } from "../assets/LogoWhite.svg";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

interface LogoProps {
  white?: boolean;
}

export default function Logo({ white }: LogoProps) {
  const navigate = useNavigate();
  return (
    <div
      className="flex justify-center items-center text-3xl text-k-red pt-4 cursor-pointer"
      onClick={() => navigate("/")}
    >
      {white ? <LogoWhiteIcon /> : <LogoIcon />}
    </div>
  );
}
