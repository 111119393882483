import clsx from "clsx";
import React from "react";

interface ImageCircleProps {
  src: string | undefined;
  onClick?: () => void;
  className?: string;
}

export default function ImageCircle({ src, onClick, className }: ImageCircleProps) {
  return (
    <img className={clsx(
      "object-cover w-8 h-8 rounded-full",
      className
    )} src={src} alt="picture" onClick={onClick} />
  );
}