import clsx from "clsx";
import React from "react";
import { ReactComponent as PlusSign } from "../assets/PlusSign.svg";
import { useCalendarStore, useUserStore } from "../Store";
import Button from "./Button";
import { mutate } from "swr";

export default function CalendarFooter() {
  const { selectedPeriods, modifying, setModifying, removePeriod } =
    useCalendarStore();
  const { me } = useUserStore();

  const handleSubmit = (event: React.MouseEvent) => {
    if (selectedPeriods.length > 0) {
      setModifying(true);
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const url = "https://gongy--katchup-api-create-katchup.modal.run";
      for (const [key, start, end] of selectedPeriods) {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ uid: me?.uid, start, end, timezone }),
        };
        fetch(url, requestOptions).then(() => {
          console.log("creation done", start, end, timezone);
          // TODO: this mutation should happen when everything is done (use ky?)
          mutate(key);
          removePeriod(key, start, end);
          if (selectedPeriods.length === 0) {
            setModifying(false);
          }
        });
      }
    }
  };

  return (
    <div className="pt-1 w-full">
      <div
        className={clsx(
          "h-16 flex justify-center items-start rounded-t-2xl p-2",
          "shadow-[0px_-2px_4px_rgba(0,0,0,0.05)] rounded-[14px_14px_0px_0px]"
        )}
      >
        {selectedPeriods.length > 0 ? (
          <Button
            onClick={handleSubmit}
            loading={modifying}
            className="w-full mx-2"
            text="I'm available"
          />
        ) : (
          <PlusSign className="w-8 h-8" />
        )}
      </div>
    </div>
  );
}
