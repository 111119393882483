import clsx from "clsx";
import React, { useState } from "react";
import { useCalendarStore, useUserStore } from "../Store";
import Button from "./Button";
import { mutate } from "swr";
import { GroupState, UserInfo } from "../utils/mytypes";
import { useNavigate } from "react-router-dom";
import { createKey } from "./Block";

export default function MatchFooter({
  start,
  end,
  selectedGroups,
  selectedMatches,
}: {
  start: number;
  end: number;
  selectedGroups: GroupState[];
  selectedMatches: UserInfo[];
}) {
  const { me } = useUserStore();
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState<boolean>(false);

  const handleSubmit = (event: React.MouseEvent) => {
    if (selectedGroups.length > 0) {
      setSubmitted(true);
      // Join the first group and add the other users
      const url = "https://gongy--katchup-api-confirm-membership.modal.run";
      const others_in_groups = selectedGroups.flatMap((group) => group.members.map(member => member.user.uid));
      const others = selectedMatches.map((user) => user.uid);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          uid: me?.uid,
          group_id: selectedGroups[0].id,
          invitees: [...others_in_groups, ...others]
        }),
      };
      fetch(url, requestOptions).then(() => {
        console.log("others added to group", others_in_groups, others);
        mutate(createKey(me?.uid ?? "", start, end));
      });
    } else if (selectedMatches.length > 0) {
      setSubmitted(true);
      const url = "https://gongy--katchup-api-add-others-to-group.modal.run";
      const others = selectedMatches.map((user) => user.uid);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ uid: me?.uid, start, end, others }),
      };
      fetch(url, requestOptions).then(() => {
        console.log("others added to group", selectedMatches);
        mutate(createKey(me?.uid ?? "", start, end));
      });
    }
  };

  return (
    <div className="pt-1 w-full">
      <div
        className={clsx(
          "h-28 flex flex-col items-center rounded-t-2xl p-2",
          "shadow-[0px_-2px_4px_rgba(0,0,0,0.05)] rounded-[14px_14px_0px_0px]"
        )}
      >
        <Button
          onClick={handleSubmit}
          disabled={selectedGroups.length === 0 && selectedMatches.length === 0}
          loading={submitted}
          className="w-full mx-2"
          text="Let's Katchup!"
        />
        <Button
          className="text-k-red text-base bg-transparent w-full items-start"
          text="Back"
          onClick={() => navigate("/")}
        />
      </div>
    </div>
  );
}
