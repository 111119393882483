import React, { useState } from "react";
import { useMount } from "react-use";
import { sendSMS, sendCode } from "./Auth";
import Logo from "../components/Logo";

import "react-phone-number-input/style.css";
import PhoneInput, {
  Value as PhoneNumber,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { RecaptchaVerifier, getAuth } from "firebase/auth";
import Button from "../components/Button";

const PhoneInputComponent = React.forwardRef<HTMLInputElement, any>(
  (props, ref) => (
    <input
      autoFocus
      ref={ref}
      {...props}
      className="text-center text-2xl font-bold outline-k-red bg-k-base"
      placeholder="Your phone number"
    />
  )
);

export default function Login() {
  const [recaptchaMounted, setRecaptchaMounted] = useState<boolean>(false);
  const [recaptchaSolved, setRecaptchaSolved] = useState<boolean>(false);
  const [phoneNumberSubmitted, setPhoneNumberSubmitted] =
    useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<PhoneNumber | undefined>();
  const [codeSent, setCodeSent] = useState<boolean>(false);
  const [verificationCode, setVerificationCode] = useState<string>("");

  useMount(() => {
    const auth = getAuth();
    (window as any).recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-div",
      {
        size: "invisible",
        callback: (response: any) => {
          console.log("recap callback!");
          setRecaptchaSolved(true);
        },
      },
      auth
    );
    setRecaptchaMounted(true);
  });

  const requestCodeForm = (
    <div className="flex flex-col items-center">
      <Logo />
      <h4 className="text-xl text-center my-4">Hey, can I get your number?</h4>
      <form
        className="flex flex-col items-center"
        onSubmit={(e) => {
          e.preventDefault();
          if (phoneNumber) {
            setPhoneNumberSubmitted(true);
            sendSMS(phoneNumber);
            setCodeSent(true);
          }
        }}
      >
        <PhoneInput
          inputComponent={PhoneInputComponent}
          className="text-lg outline-k-red"
          defaultCountry="US"
          value={phoneNumber}
          onChange={setPhoneNumber}
        />
        <Button
          disabled={!isValidPhoneNumber(phoneNumber ?? "")}
          loading={phoneNumberSubmitted}
          className="mt-8 w-full"
          text="Get verification code"
        />
      </form>
    </div>
  );

  const enterCodeForm = (
    <div className="flex flex-col items-center">
      <Logo />
      <h4 className="text-xl text-center my-4">
        <p>Enter the code we sent to</p>
        <p>{formatPhoneNumberIntl(phoneNumber ?? "")}</p>
      </h4>
      <form
        className="flex flex-col items-center"
        onSubmit={(e) => {
          e.preventDefault();
          sendCode(verificationCode);
        }}
      >
        <input
          autoFocus
          value={verificationCode}
          onChange={(e) => setVerificationCode(e.target.value)}
          className="text-center text-2xl font-bold outline-k-red bg-k-base"
          placeholder=". . . . . ."
        />
        <Button
          disabled={
            !isValidPhoneNumber(phoneNumber ?? "") ||
            verificationCode.length !== 6
          }
          className="mt-8 w-full"
          text="Continue"
        />
      </form>
    </div>
  );

  const currentForm =
    codeSent && recaptchaSolved ? enterCodeForm : requestCodeForm;
  return (
    <>
      <div id="recaptcha-div" className="invisible" />
      {recaptchaMounted && currentForm}
    </>
  );
}
