import React, { useState } from "react";
import Radio from "../components/Radio";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useUserStore } from "../Store";
import useSWR from "swr";
import { BlockState, GroupState, MemberInfo, UserInfo } from "../utils/mytypes";
import MatchFooter from "../components/MatchFooter";
import { ArrowLeft } from "lucide-react";
import ImageCircle from "../components/ImageCircle";
import GroupView from "../views/GroupView";
import clsx from "clsx";


export function TimeTextRow({type, start, end} : {type: string, start: number, end: number}) {
  const date = new Date(start * 1000);
  const weekday = date.toLocaleDateString("en-US", { "weekday": "short"}).toUpperCase();
  const monthDate = date.toLocaleDateString("en-US", { "month": "short", "day": "numeric"});
  const formattedType = type.charAt(0).toUpperCase() + type.slice(1);
  return (
    <div className="text-center">
      {/* TODO: add icon */}
      {weekday}, {monthDate}, {formattedType}
    </div>
  );
}

export function formatGroupMembersNames(members: MemberInfo[]) {
  return members
    .map(member => members.length > 1 ? member.user.name.split(" ")[0] : member.user.name)
    .join(", ");
}

function GroupRow({
  primary,
  group,
  selected,
  disabled,
  callback
} : {
  primary: boolean,
  group: GroupState;
  selected: boolean;
  disabled: boolean;
  callback: () => void;
}) {
  const { me } = useUserStore();

  const otherMembers = group.members.filter(member => member.user.uid !== me?.uid ?? "");

  return (
    <div className="relative border-b-[1px] border-b-0 border-white">
      <div
        className={clsx(
          "flex justify-between items-center py-2.5 px-4 rounded-lg",
          primary ? "bg-k-red text-white stroke-white fill-white" : "bg-k-lightred",
        )}
        onClick={(e: React.MouseEvent) => {
          e.preventDefault();
          callback();
        }}
      >
        <ImageCircle src={otherMembers[0].user.photoURL} className="w-24 h-24" />
        <div className="text-lg grow">
          <span className="ml-3">{formatGroupMembersNames(otherMembers)}</span>
        </div>
        <Radio invertColors={primary} selected={selected} />
      </div>
      {disabled && (<div className="left-0 top-0 w-full h-full absolute bg-white/40 rounded-lg" />)}
    </div>
  );
}

function MatchRow({
  name,
  url,
  selected,
  disabled,
  callback,
}: {
  name: string;
  url: string;
  selected: boolean;
  disabled: boolean;
  callback: () => void;
}) {
  return (
    <div className="relative border-b-[1px] border-b-0 border-white">
      <div
        className="flex justify-between items-center py-2.5 px-4 rounded-lg"
        onClick={(e: React.MouseEvent) => {
          e.preventDefault();
          callback();
        }}
      >
        <ImageCircle src={url} className="w-24 h-24" />
        <div className="text-lg grow">
          <span className="ml-3">{name}</span>
        </div>
        <Radio selected={selected} />
      </div>
      {disabled && (<div className="left-0 top-0 w-full h-full absolute bg-white/40 rounded-lg" />)}
    </div>
  );
}

export default function Match() {
  const { me } = useUserStore();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const type: string = searchParams.get("type") ?? "unknown";
  const start = parseInt(searchParams.get("start") ?? "0");
  const end = parseInt(searchParams.get("end") ?? "0");
  const key =
    "https://gongy--katchup-api-get-block-state.modal.run?" +
    new URLSearchParams({
      uid: me?.uid ?? "",
      start: start.toFixed(0),
      end: end.toFixed(0),
    }).toString();
  const { data, isLoading: swrLoading } = useSWR<BlockState>(key, (url) =>
    fetch(url).then((r) => r.json())
  );

  const groups = !swrLoading && data && data.active ? data.groups : [];
  const matches = !swrLoading && data && data.active ? data.matches : [];

  const [groupSet, setGroupSet] = useState<GroupState[]>([]);
  const [matchSet, setMatchSet] = useState<UserInfo[]>([]);


  const toggleMatchSet = (user: UserInfo) => {
    const index = matchSet.findIndex((n) => n.uid === user.uid);
    if (index >= 0)
      setMatchSet((set) => set.filter((n) => n.uid !== user.uid));
    else setMatchSet((set) => [...set, user]);
  };


  const toggleGroupSet = (primary: boolean, group: GroupState) => {
    const index = groupSet.findIndex((n) => n.id === group.id);
    if (index >= 0) { 
      if (primary) {
        setGroupSet([]);
        setMatchSet([]);
      } else setGroupSet((set) => set.filter((n) => n.id !== group.id));
    } else {
      setGroupSet((set) => [...set, group]);
    }
  };

  function backCallback() {
    navigate("/");
  }

  const loadingView = <div> Loading ... </div>;

  const nonPrimaryRowsDisabled = groups.length > 0 && !groupSet.includes(groups[0]);

  const innerView = (
    <div className="flex flex-col overflow-auto grow">
      {groups.length + matches.length == 0 && (<div className="text-center">Nobody here yet!</div>)}
      {groups.length > 0 ? (<div className="pt-2 pb-4">Pending requests:</div>) : (<></>)}
      {groups.map((group, i) => {console.log(i, group); return (
        <GroupRow
          key={group.id}
          primary={i === 0}
          group={group}
          disabled={i !== 0 && nonPrimaryRowsDisabled}
          selected={groupSet.includes(group)}
          callback={() => toggleGroupSet(i === 0, group)}
        />
      )})}
      {matches.length > 0 ? (<div className="pt-4 pb-4">These friends are available:</div>) : (<></>)}
      {matches.map((user) => (
        <MatchRow
          key={user.uid}
          name={user.name}
          url={user.photoURL}
          selected={matchSet.includes(user)}
          disabled={nonPrimaryRowsDisabled}
          callback={() => toggleMatchSet(user)}
        />
      ))}
      {/* <div className="w-full break-all">{JSON.stringify(data)}</div> */}
    </div>
  );

  // If user is not confirmed, show match list

  // If a group is being previewed, it should show the group view instead
  const fullList = (
    <div className="flex flex-col mx-auto max-w-screen-sm h-screen overflow-x-hidden px-2">
      <div className="grid grid-cols-8 text-center my-4 px-2">
        <ArrowLeft
          onClick={backCallback}
          className="text-k-red my-auto cursor-pointer"
          size={24}
        />
        <div className="col-span-6">
          <div className="text-3xl">Time-Matches</div>
          <TimeTextRow type={type} start={start} end={end} />
        </div>
        <div />
      </div>
      {data ? innerView : loadingView}
      <MatchFooter start={start} end={end} selectedGroups={groupSet} selectedMatches={matchSet} />
    </div>
  );

  if (data?.confirmed) {
    // assert(data.groups.length == 1);
    return (
      <GroupView group={data.groups[0]} type={type} start={start} end={end} />
    );
  } else {
    return fullList;
  }
}
