import React, { useEffect, useState } from "react";
import { updateDisplayName, updatephotoURL } from "./Auth";

import "react-phone-number-input/style.css";
import { useUserStore } from "../Store";
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";
import Logo from "../components/Logo";
import { getAuth } from "firebase/auth";

function logOut() {
  getAuth()
    .signOut()
    .then(
      function () {
        // Sign-out successful.
      },
      function (error) {
        // An error happened.
        alert(error);
      }
    );
}

export default function Profile() {
  const { me, setUser } = useUserStore();
  const navigate = useNavigate();

  const [photoFile, setPhotoFile] = useState<File | null>(null);
  const [photoPreviewURL, setPhotoPreviewURL] = useState<string | undefined>(
    undefined
  );

  const [name, setName] = useState<string>("");
  const [submitted, setSubmitted] = useState<boolean>(false);

  // Create a preview bubble as a side effect, whenever selected file is changed
  useEffect(() => {
    if (photoFile === null) {
      setPhotoPreviewURL(undefined);
      return;
    }
    const objectURL = URL.createObjectURL(photoFile);
    setPhotoPreviewURL(objectURL);
    // Free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectURL);
  }, [photoFile]);

  // If the user is missing their display name or profile picture
  const nameMissing = me && !me?.displayName;
  if (nameMissing) {
    const nameInputBox = (
      <input
        autoFocus
        value={name}
        onChange={(e) => setName(e.target.value)}
        className="text-center text-2xl font-bold outline-k-red bg-k-base"
        placeholder="Your name"
      />
    );
    return (
      <div className="flex flex-col items-center">
        <Logo />
        <h4 className="text-xl text-center my-4">Hi, what's your name?</h4>
        <form
          className="flex flex-col items-center"
          onSubmit={(e) => {
            e.preventDefault();
            updateDisplayName(name, setUser);
            setSubmitted(true);
          }}
        >
          {nameInputBox}
          <Button
            disabled={!name}
            loading={submitted}
            className="mt-8 w-full"
            text="Continue"
          />
        </form>
      </div>
    );
  }

  const photoMissing = me && !me?.photoURL;
  if (photoMissing) {
    const photoInputBubble = (
      <div className="flex items-center justify-center w-full mt-4">
        <label className="flex flex-col items-center justify-center w-48 h-48 rounded-full bg-gray-200 cursor-pointer hover:ring-2 hover:ring-k-red">
          {photoFile !== null ? (
            <img
              alt="photo preview"
              src={photoPreviewURL}
              className="object-cover	w-48 h-48 rounded-full ring-2 ring-k-red"
            />
          ) : (
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <svg
                aria-hidden="true"
                className="w-10 h-10 mb-3 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                ></path>
              </svg>
              <div className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                <p className="font-semibold text-center">Click to upload</p>
                <p className="text-center">or drag and drop</p>
              </div>
              <p className="text-xs font-light text-gray-500 dark:text-gray-400">
                SVG, PNG, JPG
              </p>
            </div>
          )}
          <input
            type="file"
            className="hidden"
            onChange={(event) => {
              const item = event.target.files?.item(0);
              if (item && item.size > 2097152) {
                alert("Please choose an image less than 2MB.");
              }
              if (item && item.size <= 2097152) {
                setPhotoFile(item);
              } else {
                setPhotoFile(null);
              }
            }}
          />
        </label>
      </div>
    );
    return (
      <div className="flex flex-col items-center">
        <Logo />
        <h4 className="text-xl text-center my-4"></h4>
        <form
          className="flex flex-col items-center"
          onSubmit={(e) => {
            e.preventDefault();
            updatephotoURL(photoFile, setUser);
            setSubmitted(true);
          }}
        >
          {photoInputBubble}
          <Button
            disabled={!photoFile}
            loading={submitted}
            className="mt-8 w-full"
            text="Continue"
          />
        </form>
      </div>
    );
  }

  navigate("/profile");
}
