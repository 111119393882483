import React from "react";
import { useUserStore } from "../Store";
import { useNavigate } from "react-router-dom";
import Logo from "../components/Logo";
import ImageCircle from "../components/ImageCircle";

export default function Navbar() {
  // let display: string = "-";
  // if (user?.phoneNumber) {
  //   display = parsePhoneNumber(user.phoneNumber)?.formatInternational() ?? "";
  // }
  const { me } = useUserStore();
  const navigate = useNavigate();

  return (
    <div className="grid grid-cols-3 my-4">
      <div></div>
      <Logo />
      <div className="flex flex-col items-end justify-center relative cursor-pointer">
        <ImageCircle
          src={me?.photoURL ?? undefined}
          className="w-8 h-8 rounded-full m-2"
          onClick={() => navigate("/profile")}
        />
        {/* <span className="ml-4 underline cursor-pointer" onClick={() => {
            updateDisplayName("", setUser);
          }}>
            Clear name
          </span> */}
      </div>
    </div>
  );
}
