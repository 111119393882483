import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "../Store";
import { GroupState } from "../utils/mytypes";
import Logo from "../components/Logo";
import { ArrowLeft } from "lucide-react";
import Button from "../components/Button";
import ImageCircle from "../components/ImageCircle";
import { TimeTextRow, formatGroupMembersNames } from "../pages/Match";
import { mutate } from "swr";
import { createKey } from "../components/Block";

interface GroupViewProps {
  group: GroupState;
  type: string;
  start: number;
  end: number;
}

function OutgoingRequestFooter({ start, end }: {start: number, end: number}) {
  const { me } = useUserStore();
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState<boolean>(false);

  const handleCancel = (event: React.MouseEvent) => {
    const url = "https://gongy--katchup-api-cancel-membership.modal.run";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ uid: me?.uid, start, end }),
    };
    setSubmitted(true);
    fetch(url, requestOptions).then(() => {
      console.log("cancel done", start, end);
      mutate(createKey(me?.uid ?? "", start, end));
    });
  };

  return (
    <div className="mt-auto flex flex-col p-2 pb-4">
      <Button
        onClick={() => navigate("/")}
        className="text-white bg-k-red grow mx-2"
        text="Back to calendar"
      />
      <Button
        className="text-k-red bg-transparent w-full items-start"
        loading={submitted}
        onClick={handleCancel}
        text="Not feeling social any more ..."
      />
    </div>
  );
}

function ConfirmationFooter() {
  const navigate = useNavigate();

  return (
    <div className="mt-auto flex flex-col p-2 pb-4">
      <Button
        onClick={() => alert("Chat is coming soon!")}
        className="text-k-red bg-white grow mx-2"
        text="Let's Chat"
      />
      <Button
        className="text-white text-base bg-transparent w-full items-start"
        onClick={() => navigate("/")}
        text="Back to calendar"
      />
    </div>
  );
}

export default function GroupView({ group, type, start, end }: GroupViewProps) {
  const { me } = useUserStore();
  const navigate = useNavigate();

  const otherMembers = group.members.filter(member => member.user.uid !== me?.uid ?? "");

  // If user is confirmed in this group, and group has other confirmed users, show confirmation
  const confirmationScreen = (
    <div className="flex flex-col mx-auto max-w-screen-sm h-screen overflow-x-hidden bg-k-red text-white">
      <div className="grid grid-cols-8 text-center mt-4">
        <ArrowLeft
          onClick={() => navigate("/")}
          className="my-auto mr-auto cursor-pointer ml-4"
          size={24}
        />
        <div className="col-span-6">
          <Logo white />
          <TimeTextRow type={type} start={start} end={end} />
        </div>
        <div />
      </div>
      <div className="flex flex-col justify-center h-full">
        <div className="text-2xl font-bold w-2/3 text-center self-center mb-16">
          You're katching up with {formatGroupMembersNames(otherMembers)}
        </div>
        <div className="flex justify-center">
          <ImageCircle
            src={me?.photoURL ?? undefined}
            className="w-40 h-40 -mr-4"
          />
          <ImageCircle
            src={otherMembers[0].user.photoURL ?? undefined}
            className="w-40 h-40"
          />
        </div>
      </div>
      <ConfirmationFooter />
    </div>
  );

  // If user is the only one confirmed in group, show request screen
  const outgoingRequestScreen = (
    <div className="flex flex-col mx-auto max-w-screen-sm h-screen overflow-x-hidden">
      <div className="grid grid-cols-8 text-center mt-4 text-k-red">
        <ArrowLeft
          onClick={() => navigate("/")}
          className="my-auto mr-auto cursor-pointer ml-4"
          size={24}
        />
        <div className="col-span-6">
          <Logo />
          <TimeTextRow type={type} start={start} end={end} />
        </div>
        <div />
      </div>
      <div className="flex flex-col justify-center h-full">
        <div className="text-2xl font-bold w-2/3 text-center self-center mb-16">
          You sent a request to katch up with {formatGroupMembersNames(otherMembers)}.
        </div>
        <div className="flex justify-center">
          <ImageCircle
            src={me?.photoURL ?? undefined}
            className="w-40 h-40 -mr-4"
          />
          <ImageCircle
            src={otherMembers[0].user.photoURL ?? undefined}
            className="w-40 h-40"
          />
        </div>
      </div>
      <OutgoingRequestFooter start={start} end={end} />
    </div>
  );

  // If user is not confirmed in group, show the option to accept
  const potentialRequestScreen = (
    <div className="flex flex-col mx-auto max-w-screen-sm h-screen overflow-x-hidden">
      <div className="grid grid-cols-8 text-center text-3xl mt-4 text-k-red">
        <ArrowLeft
          onClick={() => navigate("/")}
          className="my-auto mr-auto cursor-pointer ml-4"
          size={24}
        />
        <div className="col-span-6">
          <Logo />
          <TimeTextRow type={type} start={start} end={end} />
        </div>
        <div />
      </div>
      <TimeTextRow type={type} start={start} end={end} />
      <div className="flex flex-col justify-center h-full">
        <div className="text-2xl font-bold w-2/3 text-center self-center mb-16">
          {formatGroupMembersNames(otherMembers.slice(0, 1))} wants to katch up with you and {formatGroupMembersNames(otherMembers.slice(1))}
        </div>
        <div className="flex justify-center">
          <ImageCircle
            src={me?.photoURL ?? undefined}
            className="w-40 h-40 -mr-4"
          />
          <ImageCircle
            src={otherMembers[0].user.photoURL ?? undefined}
            className="w-40 h-40"
          />
        </div>
      </div>
      <OutgoingRequestFooter start={start} end={end} />
    </div>
  );

  const numConfirmedInGroup = group.members.reduce((sum, member) => sum + (member.status === "confirmed" ? 1 : 0), 0);
  const selfConfirmedInGroup = group.members.find(member => member.user.uid === me?.uid)?.status === "confirmed" ?? false;

  if (selfConfirmedInGroup) {
    if (numConfirmedInGroup >= 2) {
      return confirmationScreen;
    } else {
      return outgoingRequestScreen;
    }
  } else {
    return potentialRequestScreen;
  }
}
