import React, { useEffect, useState } from "react";
import { updateDisplayName, updatephotoURL } from "./Auth";

import "react-phone-number-input/style.css";
import { useUserStore } from "../Store";
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";
import Logo from "../components/Logo";
import { getAuth } from "firebase/auth";

function logOut() {
  getAuth()
    .signOut()
    .then(
      function () {
        // Sign-out successful.
      },
      function (error) {
        // An error happened.
        alert(error);
      }
    );
}

export default function Profile() {
  const { me, setUser } = useUserStore();

  const handleChangeName = (event: React.MouseEvent) => {
    event.preventDefault();
    updateDisplayName("", setUser);
  };

  const handleChangeProfilePicture = (event: React.MouseEvent) => {
    console.log("update to null.");
    event.preventDefault();
    updatephotoURL(null, setUser);
  };

  return (
    <div className="flex flex-col items-center pt-4">
      <Logo />
      <h4 className="text-xl text-center my-4">Hey, {me?.displayName}!</h4>
      <img
        alt="profile picture"
        src={me?.photoURL ?? undefined}
        className="object-cover w-48 h-48 rounded-full ring-2 ring-k-red mb-4"
      />
      <Button
        className="mb-1 bg-zinc-300 text-sm w-full"
        onClick={handleChangeName}
        text="Change name"
      />
      <Button
        className="mb-1 bg-zinc-300 text-sm w-full"
        onClick={handleChangeProfilePicture}
        text="Change profile picture"
      />
      <Button className="mb-1 text-sm w-full" onClick={logOut} text="Log out" />
    </div>
  );
}
