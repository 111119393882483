import clsx from "clsx";
import React from "react";
import Block from "./Block";

type CalendarColumnProps = {
  day: Date;
};

export default function CalendarColumn({ day }: CalendarColumnProps) {
  return (
    <div className="flex flex-col w-[46px]">
      {/* Heading */}
      <div
        className={clsx(
          "text-center text-sm px-1 py-2 uppercase bg-white rounded-md py-3",
          day.getDate() === new Date().getDate() ? "text-k-red" : "text-black",
          [0, 6].includes(day.getDay()) ? "bg-k-blue" : "bg-white"
        )}
      >
        <div className="text-xs">
          {day.toLocaleDateString(undefined, { weekday: "short" })}
        </div>
        <div className="font-bold mt-1">
          {day.toLocaleDateString(undefined, { day: "numeric" })}
        </div>
      </div>
      {/* Clickable boxes */}
      <Block
        type="morning"
        start={day.setHours(7) / 1000}
        end={day.setHours(12) / 1000}
      />
      <Block
        type="noon"
        start={day.setHours(12) / 1000}
        end={day.setHours(18) / 1000}
      />
      <Block
        type="evening"
        start={day.setHours(18) / 1000}
        end={day.setHours(23) / 1000}
      />
    </div>
  );
}
