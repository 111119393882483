import clsx from "clsx";
import React from "react";

interface RadioProps {
  invertColors?: boolean;
  selected: boolean;
}

export default function Radio({ invertColors, selected }: RadioProps) {
  return (
    <div className={clsx(
      "rounded-full border-[1px] w-6 h-6",
      invertColors ? "border-white" : "border-k-red"
    )}>
      {selected && (
        <div className={
          clsx(
            "w-[18px] h-[18px] rounded-full translate-x-[2px] translate-y-[2px]",
            invertColors ? "bg-white" : "bg-k-red"
          )} />
      )}
    </div>
  );
}
